import {useRouter} from "next/router";
import Button from "/components/common/button"
import Head from 'next/head'
import {useEffect, useMemo, useState} from "react";
import {Api} from "../api";

export default function ErrorPage({scripts}) {
    const router = useRouter();
    const [favicon, setFavicon] = useState('/favicon.ico')
    const [isBaltex, setIsBaltex] = useState(false)

    useEffect(() => {
        const suorce =       sessionStorage.getItem('previosUrl') || document.referrer;
        Api.server.addLogError(`${window.location.href}___${suorce}`, 'errors404')
        if (router.asPath !== '/404') {
            router.replace("/404")
        }
        if ((typeof window !== 'undefined' && window.location.hostname.includes('baltex'))) {
            setFavicon('/baltex/favicon.ico');
            setIsBaltex(true);
        }
        if (typeof ym !== "undefined") {
            ym(20518312, 'reachGoal', 'page404')
        }
    }, []);

    return (
        <>
            <Head>
                <title>Farkop.ru - 404 Страница не найдена</title>
                <meta
                    name="description"
                    content={'Страница не найдена'}
                    key="desc"
                />
                <link rel="shortcut icon" href={favicon} type="image/png"/>
            </Head>
            {!isBaltex ? (
            <span dangerouslySetInnerHTML={{
                __html: `
                <style>
                    :root {
                        --font: 'Golos Text';
                        --main-theme: #1247a5;
                        --main-theme-2: #1247a5;
                        --link-color: #1247a5;
                        --main-theme-hover: #0634B0;
                        --main-theme-text: #fff;
                        --main-theme-border-radius: 4px;
                        --main-theme-back: #fff;
                    }
                </style>
                `
            }}></span>
            ) : (
                <span dangerouslySetInnerHTML={{
                    __html: `
                 <style>
                    :root {
                        --font: 'Gilroy';
                        --main-theme: #09080A;
                        --main-theme-2: #09080A;
                        --link-color: #0FB1B1;
                        --main-theme-hover: #333;
                        --main-theme-text: #fff;
                        --main-theme-border-radius: 0px;
                        --main-theme-back: #F2F2F2;
                    }
                </style>
                `
                }}></span>
            )}
            <div style={{
                fontSize: '20px',
                margin: '50px 0',
                display: 'flex',
                alignItems: 'center',
                gap: '30px',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
                <span>Страница не найдена</span>
                <img src={'/404.png'}/>
                <Button label='Перейти в каталог' onClick={() => {
                    router.push('/catalog/')
                }}></Button>
            </div>

            <div dangerouslySetInnerHTML={{__html: `
            <!-- Yandex.Metrika counter -->
<script type="text/javascript" >
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(20518312, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true,
        trackHash:true,
        ecommerce:"dataLayer"
   });
</script>
<noscript><div><img src="https://mc.yandex.ru/watch/20518312" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
<!-- /Yandex.Metrika counter -->`}}></div>
        </>
    )
}